import * as React from "react"
import Layout from "@components/layout"
import Hero from "@components/hero"
import Marquee from "@components/marquee"
import Main from "@components/main"
import Footer from "@components/footer"

const IndexPage = () => {
  return (

    <Layout>
      <Hero />
      <Marquee />
      <Main />
      <Footer />
    </Layout>
  )
}

export default IndexPage
