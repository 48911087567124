import * as React from "react"
import Button from '@components/button'
import ProductViewer from '@components/product_viewer'

const SingleProduct = (props) => {


  return (
        <div className="max-w-4xl mx-auto flex flex-col md:flex-row items-center mb-4">
          <div className="md:w-2/5 px-4 mb-8">
              <h4 className="text-xl text-prussian font-black mb-3">2021 US OPEN COLLECTIBLES EDITION</h4>
              <h6 className="text-base text-prussian font-bold">LIMITED TIME</h6>
              <p className="text-base text-prussian mb-6">A unique opportunity for every tennis fan around the world to celebrate the 2021 US Open with an exclusive NFT collectible, featuring the US Open championship trophies.</p>
              <Button href={`/USOpen/products/${props.product.trim()}`}>Purchase Now</Button>
          </div>
          <div className="w-4/5 md:w-3/5 mx-auto md:px-16 md:m-0">
              <ProductViewer product={props.product} autoplay={true}/>
          </div>
        </div>
  )
}

export default SingleProduct
