import React, { useState, useEffect } from "react"
import { Fragment } from "react"
import ProductRow from '@components/product_row'
import SingleProduct from '@components/single_product'
import Button from '@components/button'
import SectionHeading from '@components/section_heading'
import Body from '@components/body'
import Star from '@components/star'
import {chunk} from '@components/utils'

const Main = (props) => {

  const [legendaryGroups, setLegendaryGroups] = useState(0)
  const [legendaryRows, setLegendaryRows] = useState(0)
  const rowsPerLoad = 5

  const star_items = [
    '1-hour backstage tour of the US Open Grounds',
    '30 minutes of play in Arthur Ashe Stadium',
    'Photo Opportunity with the US Open Trophies',
    'Your name engraved on the USTA BJK National Tennis Center Grounds',
    'Swag Bag from the 2021 US Open',
    'And more...'
  ];

  const golden_aces = chunk(process.env.GOLDEN_ACES.split(','), 5)[0];

  function groupLegendaryRows(list){

    // First reduce into 20 groups by token property
    const groups = list.reduce((r, a) => {
      if(a.tokenMetadata.properties.Legendary_Token) {
        let idx = Number(a.tokenMetadata.properties.Legendary_Token.split('of')[0])
        r[idx - 1].push(a);
      }
      return r;
    }, Array(20).fill(0).map(() => [])).map((group) => {

      // Sort each group, primary sort liveAt secondary sort createdAt
      return group.sort(function(a, b) {
        let date_a = new Date(a.liveAt).getTime()
        let date_b = new Date(b.liveAt).getTime()

        return date_a === date_b ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() : date_b - date_a;
      }).map((a) => {

	// Keep only the product ids in each group
	return a.id;
      });
    });

    setLegendaryGroups(groups)
    setLegendaryRows(groups.slice(0,rowsPerLoad))
  }

  useEffect(() => {
    fetch(process.env.GATSBY_API_KEY)
      .then(response => response.json())
      .then(firstPage => {
        fetch(`${process.env.GATSBY_API_KEY}&offset=55`)
        .then(response => response.json())
        .then(secondPage => {
          const fullProducts = firstPage.products.concat(secondPage.products);
          groupLegendaryRows(fullProducts)
        })
      })
  }, [])

  function loadMore(e){
    let current_products = legendaryRows.length
    let more_products = legendaryRows.concat(legendaryGroups.slice(current_products, current_products + rowsPerLoad))
    setLegendaryRows(more_products)
  }

  return (

   
    <Body>
      
    <main id="main" className={`p-3 md:py-10 min-h-screen pb-28`}>

      <section className="page-size">
        <SingleProduct product={process.env.OPEN_EDITION}/>
      </section>
      
      <section className="page-size mt-4 md:mt-36">

        <SectionHeading title="GOLDEN ACES" outline={"1 of 1"} break={true}>
          <p className="mb-2 max-w-lg 2xl:max-w-2xl">One-of-one cards, featuring a once in a lifetime opportunity for tennis fans. Each Golden Ace card includes player-specific benefits plus the following incredible access:</p>
          {star_items.map((item, i) => (
            <div className="my-1 font-bold uppercase text-tiny md:text-base max-w-lg 2xl:max-w-2xl" key={i}><Star />{item}</div>
          ))}
        </SectionHeading>

        

        <ProductRow items={golden_aces} per_row={3} align={"center"}/>
      
      </section>
      
      <section className=" page-size mt-4 md:mt-36">

        <SectionHeading title="LEGENDARY">
          <p className="mb-2 max-w-lg 2xl:max-w-2xl">Each NFT is unique, numbered, and highly rare -  there are only 20 available and you’ll be the only one in the world to own this specific NFT! Show your special connection and appreciation to a legendary US Open Champion.</p>
        </SectionHeading>
      
       
       {legendaryRows.length > 0 &&
        legendaryRows.map((_legendary, i) => (
          <Fragment key={i}>
            <SectionHeading outline={`${i+1} of ${legendaryGroups.length}`} />
            <ProductRow items={_legendary} />
          </Fragment>
        ))
       }
       
       {legendaryRows.length < legendaryGroups.length && 
        <Button className="mx-auto max-w-xs cursor-pointer" click={loadMore}>Load more</Button>
       }
      </section>
       
    </main>

    </Body>


  )
}

export default Main
