import * as React from "react"
import ProductViewer from '@components/product_viewer'


const ProductRow = (props) => {

  const col_size = props.per_row == 3 ? 'md:w-2/6' : 'md:w-1/5'
  const alignment = props.align === 'center' ? 'justify-center' : ''
  return (

    <div className={`my-12 text-prussian flex flex-col md:flex-row flex-wrap ${alignment}`}>
        {props.items.map(item => (
        <div className={`w-full ${col_size} my-4 px-3`} key={item}>
            <ProductViewer product={item} autoplay={false} interactive={true}/>
        </div>
        ))}
    </div>
    
  )
}

export default ProductRow