import React, {useState} from "react"

const PV = (props) => {
  const [autoplay, setAutoplay] = useState(props.autoplay)
  const product_id = props.product.trim()

  function handleHover(e, mouseEnter){
    if (props.interactive && mouseEnter != autoplay) {
      setAutoplay(mouseEnter)
      const video = e.target.shadowRoot.querySelector('token-viewer').shadowRoot.querySelector('video');
      if (mouseEnter) {
        // start video from the beginning to see flipping
        video.currentTime = 0;
        video.play()
      }
      else {
        video.pause();
        // reset video to thumbnail placement
        video.currentTime = 1.35;
      }
    }
  }
  return (
    <a href={`/USOpen/products/${product_id}`}>
      <product-viewer
          title-color="#001958"
          price-color="#001958"
          product-id={product_id}
          display-type="minimal"
          live-updates="true"
          currency="USD"
          autoplay={autoplay}
          onMouseEnter={(e) => handleHover(e, true)}
          onMouseLeave={(e) => handleHover(e, false)}
      ></product-viewer>
    </a>


  )
}

export default PV
