import React, { useState, useEffect, useRef } from "react"
import { Fragment  } from "react";

const SectionHeading = (props) => {

  const [isVisible, setIsVisible] = useState(false)  
  const titleRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
      
        setIsVisible(true)
        observer.unobserve(titleRef.current);
      }
    });
    
    observer.observe(titleRef.current);
  }, []);

  return (
    <Fragment>
        <div className="my-12 px-3 text-prussian flex flex-col md:flex-row" ref={titleRef}>
            <h4 className="text-2xl font-black flex-grow pr-10">{props.title}
            {props.break && <br/> }
             {props.outline && <span className="text-outline text-prussian">{props.outline}</span>}
             </h4>
            {props.children &&
            <div className={`text-tiny md:text-lg flex-shrink transition-opacity delay-300 duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                {props.children}
            </div>
            }
           
        </div>

    </Fragment>


  )
}

export default SectionHeading