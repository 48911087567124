import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Star = (props) => {
  return (
    <div className={"pr-2 inline-block"}>
       <StaticImage
            className="align-text-top"
            src="../../assets/images/star.png"
            placeholder="none"
            alt="Star"
          />
    </div>
 
  )
}

export default Star